import useMultipleQueries from '../../../util/rtk/useQueries';
import { useAppDispatch } from '../../store.model';
import { IUser } from '../api/user.model';
import UserAPI from '../api/UserAPI';

// Join many queries into one
// This is a custom hook that uses the `getUser` query from the `UserAPI` to fetch user data based on an array of user IDs.
function useUsers(usersIds?: string[]) {
  return useMultipleQueries<any, IUser, string>(
    UserAPI.endpoints.getUser,
    usersIds,
    { skip: !usersIds?.length }
  );
}

export default useUsers;
