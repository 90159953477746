import React, { useEffect, useMemo, useState } from 'react';
import { Group } from '../../../../../redux/groups/api/group.model';
import {
  fetchAvailableUsersToAssignGroup,
  fetchUsersRoles,
} from '../../../../../redux/users/actions';
import ConnectedGroupUserAssign from './GroupUserAssign';
import { useShowErrorMessage } from '../../../../../handlingErrors';
import { useShowMessage } from '../../../../../util/hooks';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../redux/store.model';
import { getPolyglot } from '../../../../../i18n';
import {
  Button,
  Stack,
  Tooltip,
  Typography,
  ButtonGroup,
  IconButton,
  Box,
  Avatar,
} from '@mui/material';

import { getFeatureToggle } from '../../../../../featureToggle';
import { getMyWorkspaceSelector } from '../../../../../redux/stoerkID/selectors/StoerkId.selectors';
import { getUsersByUsersIdsPropsSelector } from '../../../../../redux/users/selectors';
import SortUtil from '../../../../../util/SortUtil';
import { getRightUserUtilSelector } from '../../../../../redux/rights/selectors';
import GroupUserUpdateRole from './GroupUserUpdateRole/GroupUserUpdateRole';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { RightUserAPIResponse } from '../../../../../redux/rights/api/right.model';
// import { Add as AddIcon } from '@mui/icons-material';
import getRolesNamesDescriptions from './Constants';
import { STModal } from '../../../../commons/Modal';
import { deleteGroupUser } from '../../../../../redux/groups/actions/thunks';
import StoerkIdNoStoerkIdModal from '../../../../StoerkId/MyStoerkId/StoerkIdNoStoerkIdModal';
import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  UserIcon,
} from '../../../../../theme/icons';
import useUsers from '../../../../../redux/users/hooks/useUsers';
import { Base64 } from '../../../../../util/Base64';
import { IUser } from '../../../../../redux/users/api/user.model';
import DeleteUserConfirmationDialog from './DeleteUserConfirmationDialog/DeleteUserConfirmationDialog';

interface GroupEditUsersProps {
  group?: Group;
}

interface UserObj {
  role: string;
  roleId: string;
  roles: RightUserAPIResponse[];
  id: string;
  encodedId: string;
  name: string | null;
  location: string | null;
  email: string;
  avatarLocation: string | null;
}
function GroupEditUsers({ group }: GroupEditUsersProps) {
  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();
  const dispatch = useAppDispatch();
  const polyglot = getPolyglot();
  const featuresToggles = useAppSelector(getFeatureToggle);
  const usersRoles = useAppSelector((state) => state.users.usersRoles);
  const userLogged = useAppSelector((state) => state.auth.id);
  const usersIds = useMemo(
    () => group?.users.map((encodedUserId) => Base64.decode(encodedUserId)),
    [group?.users]
  );
  // const users = useAppSelector((state) =>
  //   getUsersByUsersIdsPropsSelector(state, {
  //     usersIds: group?.users,
  //   })
  // );
  const { data: users = [], ...rest } = useUsers(usersIds);
  // const users = [] as IUser[];
  const workspace = useAppSelector((state) => getMyWorkspaceSelector(state));
  const rightsUserUtil = useAppSelector(getRightUserUtilSelector);

  const [showMessageDeleteGroupUser, setShowMessageDeleteGroupUser] =
    useState<boolean>(false);
  const [showGroupUserAssign, setShowGroupUserAssign] =
    useState<boolean>(false);
  const [showGroupUserUpdateRole, setShowGroupUserUpdateRole] =
    useState<boolean>(false);
  const [showMessageLoadData, setShowMessageLoadData] =
    useState<boolean>(false);
  const [showNoWorkspace, setShowNoWorkspace] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<
    Partial<UserObj & { rolesId: string[] }>
  >({
    rolesId: [],
    encodedId: '',
    name: '',
  });

  const loadComponentDisplayProperties = () => {
    const showRemoveGroupUserBotton =
      group && rightsUserUtil.hasRightsToRemoveGroupUser(group.id);
    const showAssignGroupUserBotton =
      group && rightsUserUtil.hasRightsToAssignGroupUser(group.id);
    const showUserRights =
      group && rightsUserUtil.hasRightsToReadUserRights(group.id);
    const showUpdateRoleGroupUser =
      group && rightsUserUtil.hasRightsToAssignGroupUser(group.id);
    return {
      showRemoveGroupUserBotton,
      showAssignGroupUserBotton,
      showUserRights,
      showUpdateRoleGroupUser,
    };
  };

  const {
    showRemoveGroupUserBotton,
    showAssignGroupUserBotton,
    showUserRights,
    showUpdateRoleGroupUser,
  } = loadComponentDisplayProperties();

  async function loadUsersRoles() {
    try {
      setShowMessageLoadData(true);
      if (group) await dispatch(fetchUsersRoles(group.users, group.id));
      if (group) await dispatch(fetchAvailableUsersToAssignGroup(group.id));
    } catch (error) {
      showError(error);
    } finally {
      setShowMessageLoadData(false);
    }
  }

  const openGroupUserAssign = async () => {
    try {
      if (
        !workspace &&
        featuresToggles?.stoerkId &&
        featuresToggles?.stoerkIdRestrictions
      ) {
        setShowNoWorkspace(true);
        return;
      }
      if (group) await dispatch(fetchAvailableUsersToAssignGroup(group.id));
      setShowGroupUserAssign(true);
    } catch (error) {
      setShowGroupUserAssign(true);
      showError(error);
    }
  };

  const usersWhitRoles = users.map((user) => {
    const found = usersRoles.find(
      (userRole) => userRole.userId === user.encodedId
    );
    return {
      ...user,
      role: found ? found.role : '',
      roleId: found && found.roles.length > 0 ? found.roles[0].id : '',
      roles: found ? found.roles : [],
    };
  });
  const sortField = 'name';
  const resultUsers = SortUtil.multisort(
    usersWhitRoles,
    [sortField],
    undefined
  );

  /**
   * Close group user assign
   * @param bool addedUser
   */
  const closeGroupUserAssign = async (addedUser = false) => {
    setShowGroupUserAssign(false);
  };

  /**
   * Close group user update role
   */
  const closeGroupUserUpdateRole = async (updatedUser = false) => {
    if (updatedUser) {
      loadUsersRoles();
    }
    setShowGroupUserUpdateRole(false);
  };

  /**
   * Open message delete group user
   * this function opens a modal window with a message question
   */
  const openMessageDeleteGroupUser = (event: unknown, userId: string) => {
    setShowMessageDeleteGroupUser(true);
    setDeleteUserId(userId);
  };

  /**
   * Close message delete group user
   */
  const closeMessageDeleteGroupUser = () => {
    setShowMessageDeleteGroupUser(false);
    setDeleteUserId(null);
  };
  /**
   * Remove group user
   * this function call the rest api to remove a user from a group
   */
  const removeGroupUser = async (event: unknown, userId: string) => {
    try {
      if (group) await dispatch(deleteGroupUser(group.id, userId));
      showSuccess(polyglot.t('group.remove_group_user_successful_message'));
    } catch (error) {
      showError(error);
    } finally {
      closeMessageDeleteGroupUser();
    }
  };

  /**
   * Open group user update role
   * @param object event
   * @param string userId
   * @param string userName
   * @param array rolesId
   */
  const openGroupUserUpdateRole = (
    event: unknown,
    userId: string,
    userName: string,
    rolesId: string[]
  ) => {
    setSelectedUser({ encodedId: userId, name: userName, rolesId: rolesId });
    setShowGroupUserUpdateRole(true);
  };

  useEffect(() => {
    if (group?.users?.length && group.users.length > 0) {
      loadUsersRoles();
    }
  }, [group?.users]);

  const buttonAdd = (
    <Tooltip title={polyglot.t('group.tooltip.open_window_assign_user')}>
      <IconButton
        color="primary"
        size="large"
        sx={{ float: 'right' }}
        onClick={openGroupUserAssign}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

  const buttonDelete = (index: number, userId: string) => {
    return (
      <Tooltip title={polyglot.t('group.tooltip.remove_group_user')}>
        <IconButton
          id={`iconDeleteUser${index}`}
          onClick={(event) => openMessageDeleteGroupUser(event, userId)}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const buttonEdit = (user: UserObj) => {
    return (
      <Tooltip title={polyglot.t('group.tooltip.update_role_user')}>
        <IconButton
          id={`iconEditUser${user.encodedId}`}
          onClick={(event) =>
            openGroupUserUpdateRole(
              event,
              user.encodedId,
              user.name || user.email,
              user.roles.map((r) => r.id)
            )
          }
          size="small"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'avatarLocation',
      headerName: '',
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Avatar src={params.value} alt="user-avatar" sx={{ m: 0.3 }}>
          {params.value ? <UserIcon /> : null}
        </Avatar>
      ),
    },
    {
      field: 'name',
      headerName: polyglot.t('group.users.name'),
      flex: 1,
      renderCell: (params) =>
        showUserRights && (
          <Typography variant="body2">{params.value}</Typography>
        ),
    },
    {
      field: 'role',
      headerName: polyglot.t('group.users.role'),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const row = params.row as UserObj;
        const userRoles =
          row.roles && row.roles.length > 0
            ? getRolesNamesDescriptions(row.roles).map((r, id) => {
                return (
                  <div key={id.toString()}>
                    {
                      <Box display="flex" alignItems="center">
                        {`${r.name} `}
                        {r.description}
                      </Box>
                    }
                  </div>
                );
              })
            : params.value
                ?.split(',')
                .sort()
                .map((name: string, id: number) => (
                  <div key={id.toString()}>{name}</div>
                ));

        return (
          showUserRights && <Typography variant="body2">{userRoles}</Typography>
        );
      },
    },
    {
      field: 'email',
      headerName: polyglot.t('group.users.email'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      flex: 0.5,
      renderCell: (params) => {
        const row = params.row as UserObj;
        return (
          <ButtonGroup>
            {showUpdateRoleGroupUser && buttonEdit(row)}
            {userLogged !== row.encodedId &&
              showRemoveGroupUserBotton &&
              buttonDelete(+row.id, row.encodedId)}
          </ButtonGroup>
        );
      },
    },
  ];

  const rows = useMemo(
    () =>
      resultUsers.map((user, index) => {
        return {
          ...user,
          name: user.name || polyglot.t('group.users.no_name'),
          actions: user,
        } as UserObj;
      }),
    [resultUsers]
  );

  return (
    <>
      {showGroupUserAssign && group && (
        <ConnectedGroupUserAssign
          groupId={group?.id}
          closeGroupUserAssign={closeGroupUserAssign}
        />
      )}
      {showGroupUserUpdateRole && (
        <GroupUserUpdateRole
          groupId={group?.id ?? ''}
          userId={selectedUser.encodedId ?? ''}
          userName={selectedUser.name ?? ''}
          rolesId={selectedUser.rolesId ?? []}
          showGroupUserUpdateRole={showGroupUserUpdateRole}
          closeGroupUserUpdateRole={closeGroupUserUpdateRole}
        />
      )}

      <Stack spacing={1}>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={showMessageLoadData}
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
          />
        </div>

        <Box>{showAssignGroupUserBotton && buttonAdd}</Box>
      </Stack>

      <StoerkIdNoStoerkIdModal
        workspace={workspace}
        open={!!showNoWorkspace}
        onClose={() => setShowNoWorkspace(false)}
      />
      <DeleteUserConfirmationDialog
        open={showMessageDeleteGroupUser}
        onClose={closeMessageDeleteGroupUser}
        userId={deleteUserId}
        groupId={group?.id}
      />
    </>
  );
}

export default React.memo(GroupEditUsers);
