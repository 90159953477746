import { ApiEndpointQuery, QueryStatus } from '@reduxjs/toolkit/query';
import {
  QueryStateSelector,
  UseQueryStateOptions,
} from '@reduxjs/toolkit/src/query/react/buildHooks';
import { useEffect, useMemo, useReducer, useRef } from 'react';
import { useAppDispatch } from '../../redux/store.model';

interface State<Data, Argument> {
  isUninitialized: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  originalArgs: Argument[];
  data: Data[] | undefined;
  currentData: Data[] | undefined;
  error: Data[] | undefined;
}

const loadingReducer = <Data, Argument>(
  _state: State<Data, Argument> | undefined,
  originalArgs: Argument[]
) => {
  return {
    isUninitialized: false,
    isLoading: true,
    isFetching: true,
    isSuccess: false,
    isError: false,
    originalArgs: originalArgs,
    data: undefined,
    currentData: undefined,
    error: undefined,
  };
};

const fetchingReducer = <Data, Argument>(
  state: State<Data, Argument>,
  originalArgs: Argument[]
) => {
  return {
    isUninitialized: false,
    isLoading: state.data === undefined,
    isFetching: true,
    isSuccess: state.data !== undefined,
    isError: false,
    originalArgs: originalArgs,
    data: state.data,
    currentData: undefined,
    error: undefined,
  };
};
type SelectorArg = Parameters<QueryStateSelector<any, any>>[0];

const successReducer = <Data, Argument>(
  state: State<Data, Argument>,
  data: Data[],
  selectFromResult?: QueryStateSelector<any, any>
) => {
  const result: SelectorArg = {
    isUninitialized: false,
    isLoading: false,
    isFetching: false,
    isSuccess: true,
    isError: false,
    originalArgs: state.originalArgs,
    data,
    currentData: data,
    error: undefined,
    fulfilledTimeStamp: 0,
    status: QueryStatus.uninitialized,
  };
  return selectFromResult ? selectFromResult(result) : result;
};

const errorReducer = <Data, Argument>(
  state: State<Data, Argument>,
  error: Data[]
) => {
  return {
    isUninitialized: false,
    isLoading: false,
    isFetching: false,
    isSuccess: false,
    isError: true,
    originalArgs: state.originalArgs,
    data: state.data,
    currentData: undefined,
    error,
  };
};

type UseQueryResultReturn<Data, Argument> = [
  State<Data, Argument>,
  {
    success(
      data: Data[],
      selectFromResult?: QueryStateSelector<any, any>
    ): void;
    fetching(originalArgs: Argument[]): void;
    loading(originalArgs: Argument[]): void;
    error(error: Data[]): void;
  }
];

const useQueryResult = <Data, Argument>(
  originalArgs: Argument[]
): UseQueryResultReturn<Data, Argument> => {
  const [state, setState] = useReducer(
    (state: State<Data, Argument>, [reducer, value, fn]: any) =>
      reducer(state, value, fn),
    undefined,
    () => loadingReducer(undefined, originalArgs)
  );

  const setStateWrapper = useMemo(
    () => ({
      loading(originalArgs: Argument[]) {
        setState([loadingReducer, originalArgs]);
      },
      fetching(originalArgs: Argument[]) {
        setState([fetchingReducer, originalArgs]);
      },
      success(data: Data[], selectFromResult?: QueryStateSelector<any, any>) {
        setState([successReducer, data, selectFromResult]);
      },
      error(error: Data[]) {
        setState([errorReducer, error]);
      },
    }),
    []
  );

  return [state, setStateWrapper];
};

const useMultipleQueries = <
  Endpoint extends ApiEndpointQuery<any, any>,
  Data,
  Argument
>(
  endpoint: Endpoint,
  originalArgs: Argument[] = [],
  options: UseQueryStateOptions<any, any> = {}
) => {
  const endpointRef = useRef<Endpoint>();
  const dispatch = useAppDispatch();
  const [queryResult, setQueryResult] = useQueryResult<Data, Argument>(
    originalArgs
  );

  useEffect(() => {
    if (options.skip) return;
    let active = true;
    const actions = originalArgs.map((originalArg) =>
      endpoint.initiate(originalArg)
    );
    const results = actions.map((action) => dispatch(action));
    const unwrappedResults = results.map(
      (result) => result.unwrap() as Promise<Data>
    );

    if (endpointRef.current !== endpoint) {
      endpointRef.current = endpoint;
      setQueryResult.loading(originalArgs);
    } else {
      setQueryResult.fetching(originalArgs);
    }

    Promise.all(unwrappedResults)
      .then((responses: Data[]) => {
        if (active) {
          setQueryResult.success(responses, options.selectFromResult);
        }
      })
      .catch((errResponse) => {
        if (active) {
          setQueryResult.error(errResponse);
        }
      });

    return () => {
      active = false;
      results.forEach((result) => {
        result.unsubscribe();
      });
    };
  }, [endpoint, originalArgs, dispatch, setQueryResult]);

  return queryResult;
};

export default useMultipleQueries;
