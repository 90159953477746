import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { getPolyglot } from '../../../../../../i18n';
import STModal, {
  STModalProps,
} from '../../../../../commons/Modal/STModal/STModal';
import { useShowErrorMessage } from '../../../../../../handlingErrors';
import { useShowMessage } from '../../../../../../util/hooks';
import { deleteGroupUser } from '../../../../../../redux/groups/actions/thunks';
import { useAppDispatch } from '../../../../../../redux/store.model';
import LoadingButton from '@mui/lab/LoadingButton';

interface Props extends Omit<STModalProps, 'buttonActions' | 'children'> {
  userId?: string | null;
  groupId?: string;
}
function DeleteUserConfirmationDialog(props: Props) {
  const { userId, groupId } = props;
  const dispatch = useAppDispatch();
  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();
  const polyglot = getPolyglot();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.open) {
      setIsLoading(false);
    }
  }, [props.open]);

  const onClick = async (event: React.MouseEvent) => {
    try {
      setIsLoading(true);
      if (groupId && userId) await dispatch(deleteGroupUser(groupId, userId));
      showSuccess(polyglot.t('group.remove_group_user_successful_message'));
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
      props?.onClose?.();
    }
  };
  return (
    <STModal
      {...props}
      disabledClose={isLoading}
      buttonActions={
        <>
          <Button onClick={props.onClose} variant="text" disabled={isLoading}>
            {polyglot.t('general.no')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={onClick}
            loading={isLoading}
          >
            {polyglot.t('general.yes')}
          </LoadingButton>
        </>
      }
    >
      <p>{polyglot.t('group.remove_group_user_message')}</p>
    </STModal>
  );
}

export default DeleteUserConfirmationDialog;
