import { createApi } from '@reduxjs/toolkit/dist/query/react';
import axiosBaseQuery from '../../../axios/axiosBaseQuery';

export const ReportRealTimeAPI = createApi({
  reducerPath: 'reports_realtime',
  tagTypes: ['report'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/' }),
  endpoints: (build) => ({
    getReportsByGroupId: build.query<
      ReportRealTimeResult,
      { encodedGroupId: string; spec: ReportRealTimeSpec }
    >({
      query: ({ encodedGroupId, spec }) => ({
        url: `report/group/${encodedGroupId}/report`,
        invalidatesTags: ['report'],
        data: spec,
        method: 'POST',
      }),
    }),
  }),
});

export default ReportRealTimeAPI;
