import React, { useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { getPolyglot } from '../../../../i18n';
import ElevationScroll from '../ElevationScroll';

export interface STModalProps extends DialogProps {
  open: boolean;
  title?: string;
  iconURL?: string;

  onClose?: () => unknown;
  disabledClose?: boolean;
  tabs?: JSX.Element;
  buttonActions?: JSX.Element;
  children: React.ReactNode;
}

export function STModal(props: STModalProps) {
  const {
    iconURL,
    title,
    disabledClose,
    onClose,
    buttonActions,
    tabs,
    children,
    open,
    ...dialogProps
  } = props;
  const polyglot = getPolyglot();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const contentRef = useRef(null);

  return (
    <Dialog
      onClose={!disabledClose ? onClose : undefined}
      fullScreen={fullScreen}
      open={!!open}
      {...dialogProps}
      aria-labelledby="form-dialog-title"
    >
      <ElevationScroll target={contentRef.current}>
        <AppBar
          position="static"
          variant="elevation"
          sx={{ zIndex: 1 }}
          color="transparent"
        >
          <Toolbar disableGutters sx={{ paddingX: 2 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {iconURL ? (
                <Box
                  component="img"
                  sx={{ height: '1.2em', mr: 1, verticalAlign: 'middle' }}
                  src={iconURL}
                  alt="icon_header"
                ></Box>
              ) : null}
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              disabled={disabledClose}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          {tabs}
        </AppBar>
      </ElevationScroll>
      <DialogContent ref={contentRef}>{children}</DialogContent>
      {buttonActions && <DialogActions>{buttonActions}</DialogActions>}
    </Dialog>
  );
}

export default STModal;
